import { template as template_be2b3a63d01e43a2990123235b76fd80 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_be2b3a63d01e43a2990123235b76fd80(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
