import { template as template_933e98095515416381ff30c3614f740b } from "@ember/template-compiler";
const SidebarSectionMessage = template_933e98095515416381ff30c3614f740b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
