import { template as template_6c158804eff649f38c4e0ac16d62e75b } from "@ember/template-compiler";
const FKLabel = template_6c158804eff649f38c4e0ac16d62e75b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
